import './polyfills/scrollingElement';
import Mainnav from './components/Mainnav';
import Hero from './components/Hero';
import Footer from './components/Footer';
import ImageText from './components/ImageText';
import Gallery from './components/Gallery';
import ApplicationForm from './components/ApplicationForm';
import Teachers from './components/Teachers';
import Popup from './components/Popup';
import TicketLink from './components/TicketLink';
import TicketForm from './components/TicketForm';

document.bpOffscreenNavigation = 752;

if (document.querySelector('.mainnav')) {
  document.mainnav = new Mainnav(document.querySelector('.mainnav'));
}

if (document.querySelector('.hero')) {
  document.hero = new Hero(document.querySelector('.hero'));
}

if (document.querySelector('.footer')) {
  document.footer = new Footer(document.querySelector('.footer'));
}

document.ImageTexts = [];
[...document.querySelectorAll('.image-text')].forEach((element) => {
  document.ImageTexts.push(new ImageText(element));
});

document.gallerys = [];
[...document.querySelectorAll('.gallery')].forEach((element) => {
  document.gallerys.push(new Gallery(element));
});

document.popups = [];
[...document.querySelectorAll('.popup')].forEach((element) => {
  document.popups.push(new Popup(element));
});

if (document.querySelector('.application-form')) {
  document.applicationForm = new ApplicationForm(document.querySelector('.application-form'));
}

if (document.querySelector('.teachers')) {
  document.teachers = new Teachers(document.querySelector('.teachers'));
}

document.ticketLinks = [];
[...document.querySelectorAll('a[data-ticket-id]')].forEach((element) => {
  document.ticketLinks.push(new TicketLink(element));
});

if (document.querySelector('.ticket-form')) {
  document.ticketForm = new TicketForm(document.querySelector('.ticket-form'));
}
