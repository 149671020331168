class Hero {
  constructor(element) {
    const imageElement = element.querySelector('.hero__image');
    const videoContainerElements = imageElement.querySelectorAll('.hero__video');
    const videoElements = imageElement.querySelectorAll('video');

    function updateStyles() {
      const { scrollTop } = document.scrollingElement;
      if (videoContainerElements.length === 3) {
        videoContainerElements[0].style.transform = `translateY(${scrollTop * 0.08}px)`;
        videoContainerElements[1].style.transform = `translateY(${scrollTop * 0.05}px)`;
        videoContainerElements[2].style.transform = `translateY(${scrollTop * 0.1}px)`;
      } else if (imageElement) {
        imageElement.style.transform = `translateY(${scrollTop * 0.08}px)`;
      }
    }

    document.addEventListener('scroll', updateStyles, {
      passive: true,
    });

    videoElements.forEach((videoElement) => {
      videoElement.addEventListener('ended', () => {
        Object.assign(videoElement, {
          currentTime: 8 + (3 / 25),
        });
        videoElement.play();
      });
    });

    if (videoContainerElements.length > 0) {
      updateStyles();
    }
  }
}

export default Hero;
