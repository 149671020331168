class Mainnav {
  constructor(element) {
    const buttonElement = document.querySelector('button[aria-controls="mainnav"]');

    this.element = element;
    this.buttonElement = buttonElement;

    buttonElement.addEventListener('click', () => {
      if (buttonElement.getAttribute('aria-expanded') === 'false') {
        this.open();
      } else {
        this.close();
      }
    });
  }

  open() {
    const { element, buttonElement } = this;
    buttonElement.setAttribute('aria-expanded', 'true');
  }

  close() {
    const { element, buttonElement } = this;
    buttonElement.setAttribute('aria-expanded', 'false');
  }
}

export default Mainnav;
