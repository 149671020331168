class Footer {
  constructor(element) {
    const videoElements = element.querySelectorAll('video');

    videoElements.forEach((videoElement) => {
      videoElement.addEventListener('ended', () => {
        Object.assign(videoElement, {
          currentTime: 8 + (3 / 25),
        });
        videoElement.play();
      });
    });
  }
}

export default Footer;
