class TicketForm {
  constructor(element) {
    this.element = element;
    this.tableElement = element.querySelector('.ticket-form__table');
    this.data = null;
    this.get();

    element.addEventListener('submit', (event) => {
      this.submit(event);
    });
  }

  submit(event) {
    const { element } = this;
    const formData = new FormData(element);
    const successElement = element.closest('.m-tickets').querySelector('.m-tickets__success');

    event.preventDefault();

    function removeErrors() {
      [...element.querySelectorAll('.error')].forEach((errorElement) => errorElement.remove());
    }

    function showError(data) {
      const { error } = data;
      const errorElement = document.createElement('div');
      errorElement.classList.add('error');
      errorElement.textContent = error;
      const containerElement = element.querySelector('.ticket-form__container');
      containerElement.appendChild(errorElement);
    }

    function showSuccess() {
      element.scrollIntoView({
        behavior: 'smooth',
      });
      element.classList.add('is-hidden');
      successElement.classList.add('is-hidden');
      setTimeout(() => {
        element.hidden = true;
        successElement.hidden = false;
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            successElement.classList.remove('is-hidden');
          });
        });
      }, 300);
    }

    removeErrors();

    fetch(element.action, {
      method: 'POST',
      credentials: 'same-origin',
      body: formData,
    }).then((response) => {
      console.info(response);
      if (response.status === 200) {
        return response.json();
      }

      const data = {
        error: 'Fatal Server Error.',
      };
      showError(data);
    }).then((data) => {
      if (data.error) {
        showError(data);
      } else {
        showSuccess();
      }
    });
  }

  get() {
    fetch('/cart', {
      credentials: 'same-origin',
    }).then((response) => response.json()).then((data) => {
      this.data = data;
      this.paint();
    });
  }

  remove(element) {
    console.log(element);
    const removeData = {
      id: element.dataset.id,
    };

    fetch('/cart/remove', {
      method: 'post',
      credentials: 'same-origin',
      body: JSON.stringify(removeData),
    }).then((result) => result.json()).then((data) => {
      console.log(data);
      this.data = data;
      this.paint();
    });
  }

  update(element) {
    const selectQuantity = element.querySelector('select[name="quantity"]');
    const selectQuantityReduced = element.querySelector('select[name="quantityReduced"]');
    console.log(element);
    const updateData = {
      id: element.dataset.id,
      regular: selectQuantity.value,
      reduced: selectQuantityReduced.value,
    };

    fetch('/cart/update', {
      method: 'post',
      credentials: 'same-origin',
      body: JSON.stringify(updateData),
    }).then((result) => result.json()).then((data) => {
      console.log(data);
      this.data = data;
      this.paint();
    });
  }

  paint() {
    const { tableElement, data } = this;
    const tbodyElement = tableElement.querySelector('tbody');
    tbodyElement.innerHTML = '';
    const tfootElement = tableElement.querySelector('tfoot');
    const tfootThElement = tfootElement.querySelector('th');
    let sum = 0;

    const getSelect = (quantity, name, trElement) => {
      const selectElement = document.createElement('select');
      selectElement.name = name;
      let i = 0;
      while (i <= 10) {
        const optionElement = document.createElement('option');
        if (i > 0) {
          optionElement.value = i;
          optionElement.innerText = i;
          if (quantity === i) {
            optionElement.selected = true;
          }
        }
        selectElement.appendChild(optionElement);
        i += 1;
      }

      selectElement.addEventListener('change', () => {
        this.update(trElement);
      });

      return selectElement;
    };

    data.forEach((item) => {
      const trElement = document.createElement('tr');
      trElement.dataset.id = item.id;
      const thElement = document.createElement('th');
      thElement.innerHTML = `${item.text} <br>`;
      const deleteButton = document.createElement('button');
      deleteButton.type = 'button';
      if (location.pathname.startsWith('/en/')) {
        deleteButton.innerText = 'remove';
      } else {
        deleteButton.innerText = 'entfernen';
      }
      deleteButton.addEventListener('click', () => {
        this.remove(trElement);
      });
      thElement.appendChild(deleteButton);

      const tdPriceElement = document.createElement('td');
      tdPriceElement.appendChild(getSelect(item.regular, 'quantity', trElement));
      tdPriceElement.appendChild(document.createTextNode(`${item.price} Euro`));
      const tdPriceReducedElement = document.createElement('td');
      tdPriceReducedElement.appendChild(getSelect(item.reduced, 'quantityReduced', trElement));
      tdPriceReducedElement.appendChild(document.createTextNode(`${item.priceReduced} Euro`));
      const tdSumElement = document.createElement('td');
      tdSumElement.textContent = `${item.sum} Euro`;

      trElement.appendChild(thElement);
      trElement.appendChild(tdPriceElement);
      trElement.appendChild(tdPriceReducedElement);
      trElement.appendChild(tdSumElement);
      tbodyElement.appendChild(trElement);
      sum += item.sum;
    });

    tfootThElement.textContent = `${sum} Euro`;
  }
}

export default TicketForm;
