class Teachers {
  constructor(element) {
    const ulElement = element.querySelector('ul');
    const scrollContainerElement = element.querySelector('.teachers__scroll-container');
    let scrollLeft = 0;
    let { offsetTop, offsetHeight } = element;
    let mouseXStart = 0;
    let mouseXMovement = 0;
    let mouseDown = false;
    let scrollLeftStart = 0;

    function setPositions() {
      ({ offsetTop, offsetHeight } = element);
    }

    function easeOutQuad(t) {
      return t * (2 - t);
    }

    function updateSrollLeft() {
      scrollLeft = document.scrollingElement.scrollTop + window.innerHeight - offsetTop - offsetHeight * 0.5;
    }

    function draw() {
      if (scrollLeft < 0) {
        ulElement.style.transform = `translateX(${easeOutQuad(scrollLeft * 0.05) * -1}px)`;
      } else {
        ulElement.style.transform = '';
      }
      requestAnimationFrame(draw);
    }

    document.addEventListener('scroll', updateSrollLeft, {
      passive: true,
    });
    document.addEventListener('resize', () => {
      setPositions();
    });

    if (!('ontouchstart' in window || navigator.maxTouchPoints)) {
      element.addEventListener('mousedown', (event) => {
        mouseDown = true;
        mouseXStart = event.clientX;
        mouseXMovement = 0;
        scrollLeftStart = scrollContainerElement.scrollLeft;
      });
      window.addEventListener('mousemove', (event) => {
        if (mouseDown) {
          mouseXMovement = mouseXStart - event.clientX;
          scrollContainerElement.scrollLeft = scrollLeftStart + mouseXMovement;
        }
      });
      window.addEventListener('mouseup', () => {
        mouseDown = false;
      });
    }

    updateSrollLeft();
    requestAnimationFrame(draw);
  }
}

export default Teachers;
