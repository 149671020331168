class ImageText {
  constructor(element) {
    const imageElement = element.querySelector('.image-text__image');
    const imageImgElement = imageElement.querySelector('video, img');

    function updateStyles() {
      if (window.matchMedia('(min-width: 896px)').matches) {
        const { scrollTop } = document.scrollingElement;
        const { offsetTop } = element;
        imageImgElement.style.transform = `translateY(${(scrollTop - offsetTop) * 0.1}px)`;
      }
    }

    document.addEventListener('scroll', updateStyles, {
      passive: true,
    });

    updateStyles();
  }
}

export default ImageText;
