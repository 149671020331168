class InputFile {
  constructor(element) {
    const inputElement = element.querySelector('input[type=file]');
    const strongElement = element.querySelector('strong');
    const spanElement = element.querySelector('span');

    function returnFileSize(number) {
      if (number < 1024) {
        return `${number} bytes`;
      }
      if (number >= 1024 && number < 1048576) {
        return `${(number / 1024).toFixed(1)} KB`;
      }
      return `${(number / 1048576).toFixed(1)} MB`;
    }

    spanElement.addEventListener('click', (event) => {
      strongElement.hidden = false;
      spanElement.hidden = true;
      inputElement.value = '';
      event.preventDefault();
    });

    inputElement.addEventListener('change', () => {
      if (inputElement.files.length > 0) {
        const { name, size } = inputElement.files[0];
        spanElement.textContent = `${name} (${returnFileSize(size)})`;
        strongElement.hidden = true;
        spanElement.hidden = false;
      }
    });
  }
}

export default InputFile;
