class Popup {
  constructor(element) {
    const popup = this;
    const { id } = element;

    this.element = element;
    this.contentElement = element.querySelector('.content');

    function onHashChange() {
      if (window.location.hash.substr(2) === id) {
        popup.open();
      } else {
        popup.close();
      }
    }
    function onClick(event) {
      if (event.target === element) {
        window.location.hash = '#!';
      }
    }
    window.addEventListener('hashchange', onHashChange);
    element.addEventListener('click', onClick);

    onHashChange();
  }

  open() {
    const { element } = this;
    element.hidden = false;
    document.body.classList.add('has-popup');

    function onKeypress(event) {
      if (event.key === 'Escape') {
        window.location.hash = '#!';
      }
    }

    document.addEventListener('keypress', onKeypress, {
      once: true,
    });

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        element.classList.remove('is-hidden');
      });
    });
  }

  close() {
    const { element } = this;

    function onTransitionend() {
      element.removeEventListener('transitionend', onTransitionend);
      if (element.classList.contains('is-hidden')) {
        element.hidden = true;
      }
    }

    if (element.hidden === false) {
      element.removeEventListener('transitionend', onTransitionend);
      element.removeEventListener('transitioncancel', onTransitionend);
      element.addEventListener('transitionend', onTransitionend, {
        once: true,
      });
      element.addEventListener('transitioncancel', onTransitionend, {
        once: true,
      });

      document.body.classList.remove('has-popup');
      element.classList.add('is-hidden');
    }
  }
}

export default Popup;
