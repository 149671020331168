import InputFile from './InputFile';

class ApplicationForm {
  constructor(element) {
    const typeElements = element.querySelectorAll('input[name="type"]');
    const studentElement = element.querySelector('input[name="student"]');
    const attendedElement = element.querySelector('input[name="attended"]');

    this.element = element;
    this.type = 'individual';
    this.student = false;
    this.inputElements = element.querySelectorAll('button, input, select, textarea');

    if (studentElement) {
      this.studentSpanElement = studentElement.closest('label').querySelector('span');
      this.studentSpanElement.dataset.textIndividual = this.studentSpanElement.textContent;
      studentElement.addEventListener('change', (event) => {
        this.student = event.target.checked;
        this.updateForm();
      });
    }

    if (attendedElement) {
      this.attendedSpanElement = attendedElement.closest('label').querySelector('span');
      this.attendedSpanElement.dataset.textIndividual = this.attendedSpanElement.textContent;
      attendedElement.addEventListener('change', (event) => {
        this.attended = event.target.checked;
        this.updateForm();
      });
    }

    [...typeElements].forEach((typeElement) => {
      if (typeElement.checked === true) {
        this.type = typeElement.value;
      }
      typeElement.addEventListener('change', (event) => {
        this.type = event.target.value;
        this.updateForm();
      });
    });

    [...element.querySelectorAll('.input-file')].forEach((inputFileElement) => new InputFile(inputFileElement));

    element.addEventListener('submit', (event) => {
      this.submit(event);
    });

    this.updateForm();
  }

  submit(event) {
    const {
      element,
      inputElements,
      attended,
    } = this;
    const formData = new FormData(element);
    const successElement = element.closest('.application').querySelector('.application__success');

    function removeErrors() {
      [...element.querySelectorAll('.error')].forEach((errorElement) => errorElement.remove());
    }

    function showError(data) {
      const { error, details, parentElement } = data;
      const errorElement = document.createElement('div');
      errorElement.classList.add('error');
      errorElement.textContent = error;
      if (details && details.name) {
        const { name } = details;
        const inputElement = element.querySelector(`[name="${name}"]`);
        const labelElement = inputElement.closest('label');
        labelElement.scrollIntoView({
          behavior: 'smooth',
        });
        labelElement.appendChild(errorElement);
      } else if (parentElement) {
        parentElement.appendChild(errorElement);
      } else {
        const containerElement = element.querySelector('.application-form__container');
        containerElement.appendChild(errorElement);
      }
    }

    function showSuccess() {
      element.scrollIntoView({
        behavior: 'smooth',
      });
      element.classList.add('is-hidden');
      successElement.classList.add('is-hidden');
      setTimeout(() => {
        element.hidden = true;
        successElement.hidden = false;
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            successElement.classList.remove('is-hidden');
          });
        });
      }, 300);
    }

    function showLoadingScreen() {
      [...inputElements].forEach((inputElement) => {
        inputElement.setAttribute('disabled', true);
      });
      element.classList.add('is-loading');
    }

    function hideLoadingScreen() {
      [...inputElements].forEach((inputElement) => {
        inputElement.removeAttribute('disabled');
      });
      element.classList.remove('is-loading');
    }

    showLoadingScreen();
    removeErrors();

    if (!attended
    && element.querySelector('[name="demo_file"]').value === ''
    && element.querySelector('[name="links"]').value === '') {
      event.preventDefault();
      showError({
        error: element.querySelector('#application').dataset.errorMessage,
        parentElement: element.querySelector('#application'),
        details: {
          name: 'demo_file',
        },
      });
      hideLoadingScreen();
    } else {
      fetch(element.action, {
        method: 'POST',
        credentials: 'same-origin',
        body: formData,
      }).then((response) => {
        console.info(response);
        hideLoadingScreen();
        if (response.status === 200) {
          return response.json();
        }

        const data = {
          error: 'Fatal Server Error.',
        };
        showError(data);
      }).then((data) => {
        if (data.error) {
          showError(data);
        } else {
          showSuccess();
        }
        console.info(data);
      });
    }

    event.preventDefault();
  }

  updateForm() {
    const {
      element,
      type,
      student,
      attended,
      inputElements,
      studentSpanElement,
      attendedSpanElement,
    } = this;

    const asteriskBlueprintElement = document.createElement('span');
    asteriskBlueprintElement.classList.add('form__asterisk');
    if (document.documentElement.lang === 'de') {
      asteriskBlueprintElement.setAttribute('title', 'Pflichtfeld');
    } else {
      asteriskBlueprintElement.setAttribute('title', 'required field');
    }

    function getAsteriskParentElement(inputElement) {
      if (inputElement.type !== 'radio') {
        const labelElement = inputElement.closest('label');
        if (labelElement && labelElement.querySelector('span')) {
          return labelElement.querySelector('span');
        }
      }
      const divElement = inputElement.closest('div');
      if (divElement && divElement.classList.contains('application-form__fieldset')) {
        return divElement.querySelector('h3');
      }
      return null;
    }
    function addAsteriskToInputElement(inputElement) {
      const asteriskParentElement = getAsteriskParentElement(inputElement);
      if (asteriskParentElement && !asteriskParentElement.querySelector('.form__asterisk')) {
        asteriskParentElement.appendChild(asteriskBlueprintElement.cloneNode());
      }
    }
    function removeAsteriskFromInputElement(inputElement) {
      const asteriskParentElement = getAsteriskParentElement(inputElement);
      if (asteriskParentElement) {
        const asteriskElement = asteriskParentElement.querySelector('.form__asterisk');
        if (asteriskElement) {
          asteriskElement.remove();
        }
      }
    }

    if (studentSpanElement) {
      if (type === 'ensemble') {
        studentSpanElement.textContent = studentSpanElement.dataset.textEnsemble;
      } else {
        studentSpanElement.textContent = studentSpanElement.dataset.textIndividual;
      }
    }
    if (attendedSpanElement) {
      if (type === 'ensemble') {
        attendedSpanElement.textContent = attendedSpanElement.dataset.textEnsemble;
      } else {
        attendedSpanElement.textContent = attendedSpanElement.dataset.textIndividual;
      }
    }

    element.querySelector('#ensemble-member').hidden = type !== 'ensemble';
    element.querySelector('[name="ensemble_member"]').required = type === 'ensemble';
    element.querySelector('#period').hidden = type !== 'ensemble';
    [...element.querySelectorAll('[name="period"]')].forEach((periodRadioElement) => {
      periodRadioElement.required = type === 'ensemble';
    });

    element.querySelector('#teachers-report').hidden = !student;
    element.querySelector('[name="teachers_report"]').required = student;
    element.querySelector('#university').hidden = !student;
    element.querySelector('[name="university"]').required = student;

    if (attended) {
      element.querySelector('#teachers-report').hidden = true;
      element.querySelector('[name="recommendation"]').required = false;
      element.querySelector('[name="teachers_report"]').required = false;
    } else {
      element.querySelector('[name="recommendation"]').required = true;
    }
    element.querySelector('#recommendation-application').hidden = attended;

    // update required fields
    [...inputElements].forEach((inputElement) => {
      if (inputElement.required) {
        addAsteriskToInputElement(inputElement);
      } else {
        removeAsteriskFromInputElement(inputElement);
      }
    });
  }
}

export default ApplicationForm;
