class Gallery {
  constructor(element) {
    const pictureElements = element.querySelectorAll('picture');
    let activePictureElement;

    function openPicture(pictureElement) {
      if (window.matchMedia(`(min-width: ${752 / 16}em)`).matches) {
        [...pictureElements].forEach((_pictureElement) => {
          if (pictureElement === _pictureElement) {
            _pictureElement.style.flexBasis = '657px';
          } else {
            _pictureElement.style.flexBasis = '13%';
          }
        });
      }
    }

    function closeAllPicture() {
      [...pictureElements].forEach((pictureElement) => {
        pictureElement.style.flexBasis = '';
      });
    }

    [...pictureElements].forEach((pictureElement) => {
      pictureElement.addEventListener('click', () => {
        if (pictureElement === activePictureElement) {
          closeAllPicture();
          activePictureElement = null;
        } else {
          openPicture(pictureElement);
          activePictureElement = pictureElement;
        }
      });
    });

    window.addEventListener('resize', () => {
      if (!window.matchMedia(`(min-width: ${752 / 16}em)`).matches) {
        closeAllPicture();
      }
    });
  }
}

export default Gallery;
