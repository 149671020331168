class TicketLink {
  constructor(element) {
    const id = element.dataset.ticketId;
    element.addEventListener('click', (event) => {
      event.preventDefault();
      const data = {
        id,
      };
      fetch('/cart/add', {
        method: 'post',
        credentials: 'same-origin',
        body: JSON.stringify(data),
      }).then(() => {
        window.location.href = element.href;
      });
    });
  }
}

export default TicketLink;
